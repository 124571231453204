
import MixLocales from "@/mixins/MixLocales.js";
export default {
  mixins: [MixLocales],
  data() {
    return {
      form: {
        footer_email: null
      }

    }

  },
  methode: {
    //   send() {
    //     console.log(this.form.footer_email)
    //     try {
    //       const form = new FormData();
    //       form.append('from', this.form.footer_email)
    //       form.append('subject', "Contact from Site Achmitech")
    //       this.$axios.post('https://achmitech.com/send-mail/index.php', form
    //         , {
    //           "Content-Type": "multipart/form-data"
    //         })
    //         .then(response => {
    //           console.log(response.form);
    //           // handle the response from the server
    //         })
    //     }
    //     catch (error) {
    //       console.log(error);
    //     };
    //     // Reset form fields after submission
    //     this.$refs.myForm.reset();
    //   }
    // },
    // validations() {
    //   return {
    //     form: {
    //       footer_email: { email, required },
    //     }
    //   }

  },
  computed: {
    solutions() {
      return this.$store.state.solutions;
    },
    socialNetworks() {
      return this.$store.state.socialNetworks;
    },
    menus() {
      return this.$store.state.menus;
    },
    name() {
      return this.$store.state.name;
    },
  },
};
